import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				О нас | Discover WheelEase Rentals
			</title>
			<meta name={"description"} content={"Отправляйтесь в путешествие по нехоженым дорогам с компанией WheelEase Rentals"} />
			<meta property={"og:title"} content={"О нас | Discover WheelEase Rentals"} />
			<meta property={"og:description"} content={"Отправляйтесь в путешествие по нехоженым дорогам с компанией WheelEase Rentals"} />
			<meta property={"og:image"} content={"https://groxina.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://groxina.com/img/3224646.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://groxina.com/img/3224646.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://groxina.com/img/3224646.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						Наше путешествие начинается
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						О нас
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				В компании WheelEase Rentals мы понимаем, что каждая поездка на автомобиле - это глава в истории вашей жизни. При создании нашей компании мы руководствовались простой, но глубокой идеей обеспечения беспроблемных путешествий. Наш автопарк рассчитан на всех, от одиноких искателей приключений до семейных путешественников, и мы стремимся к тому, чтобы ваши путешествия были не просто путешествиями, а воспоминаниями, которые вы создаете по пути.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://groxina.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Драйв, стоящий за WheelEase
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					В основе нашей работы лежит убеждение, что путешествия должны быть доступными и приятными для всех. Мы создали наши услуги с учетом удобства, качества и гибкости, которые необходимы вам для путешествий в вашем собственном темпе. С WheelEase каждое путешествие - это возможность открыть для себя новые горизонты и создать неизгладимые воспоминания.
					</Text>
				</Box>
				<Image src="https://groxina.com/img/5.jpg" display="block" width="100%" md-order="-1" />
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
				Почему WheelEase?
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
					Разнообразный автопарк
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Наш широкий выбор автомобилей гарантирует, что вы найдете идеального компаньона для вашего путешествия, независимо от пункта назначения.
						<br />
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Бескомпромиссное качество
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Каждый автомобиль в нашем парке проходит тщательное техническое обслуживание и чистку, что гарантирует надежность и комфорт вождения.
						<br />
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Клиентоориентированный подход
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Мы уделяем первостепенное внимание вашему опыту, предоставляя индивидуальные услуги, гибкое расписание и круглосуточную поддержку, обеспечивая спокойствие во время ваших путешествий.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Прозрачные цены
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					С WheelEase вы видите то, что получаете. Наслаждайтесь четкими, конкурентоспособными ценами, не беспокоясь о скрытых расходах.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});